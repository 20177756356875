/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
import ErrorBoundary from 'components/pages/error-boundary';
import Footer from 'components/ui-components/footer';
import gsap from 'gsap';
import LocomotiveScroll from 'locomotive-scroll';
import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import { Box, Container, jsx } from 'theme-ui';
import GridOverlay from 'utils/helpers/grid-overlay';
import useBreakPoint from 'utils/hooks/useBreakpoint';

export const PageLayout = React.forwardRef(
  ({ children, innerContainerProps, id, onScroll, ...props }, ref) => {
    const { sx, ...innerContainerRest } = innerContainerProps || {};
    const breakpoint = useBreakPoint();

    useEffect(() => {
      const elementsToFade = document.querySelector('[data-transition-fade]');
      elementsToFade &&
        gsap.to(elementsToFade, { y: 0, opacity: 1, duration: 0.7 });

      const scroll = new LocomotiveScroll({
        el: document.querySelector('html'),
        smooth: true,
        lerp: breakpoint !== 0 ? 0.05 : 0.3,
        offset: ['5%', '10%'], // [bottom, top]
        smoothMobile: false,
        scrollFromAnywhere: false,
      });

      const debouncedOnScroll = throttle((e) => onScroll(e), 500);

      onScroll &&
        scroll.on('scroll', (e) => {
          // console.log('scroll');
          debouncedOnScroll(e);
        });

      const time = setTimeout(function () {
        if (scroll) {
          scroll.update();
        }
      }, 700);

      return () => {
        if (scroll) {
          scroll.destroy();
        }
        clearTimeout(time);
      };
    }, [breakpoint, id]);

    return (
      <Box
        as="page-container"
        ref={ref}
        id={id}
        sx={{ position: 'relative', transform: 'translate(0px, 50px)' }}
        {...props}
      >
        <ErrorBoundary>
          <Container
            as="section"
            data-transition-fade
            sx={{
              paddingLeft: [3, 0],
              paddingRight: [3, 0],
              paddingTop: [6, null, '15vh'],
              position: 'relative',
              maxWidth: 'none',
              ...sx,
            }}
            {...innerContainerRest}
          >
            {children}
          </Container>
        </ErrorBoundary>
        <GridOverlay />
        <Footer data-scroll-section />
      </Box>
    );
  },
);

export default PageLayout;
