/** @jsxRuntime classic */
/** @jsx jsx */
import Image from 'components/ui-components/image';
import Link from 'components/ui-components/transition-link';
import { useEffect, useRef } from 'react';
import { Box, Grid, jsx, Text } from 'theme-ui';

const NewsItem = ({ title, image, slug, background, publishDate, ...rest }) => {
  const containerRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(function () {
      if (containerRef.current && background) {
        containerRef.current.style.backgroundColor = background;
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [background]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridColumn: 'span 4',
      }}
      {...rest}
    >
      <Link
        to={'/news/' + slug.current}
        ref={containerRef}
        sx={{
          fontSize: '2.4rem',
          lineHeight: '2.9rem',
          fontFamily: 'medium',
          backgroundColor: 'transparent',
        }}
      >
        <Image
          alt={image?.alt}
          image_asset={image}
          width={600}
          height={800}
          format="portrait"
          sx={{
            marginBottom: 3,
            mixBlendMode: 'multiply',
            filter: 'grayscale(1)',
            width: '100%',

            '&:hover': {
              mixBlendMode: 'unset',
              filter: 'grayscale(0)',
            },
          }}
          data-scroll
          data-scroll-repeat
        />
        <Text
          data-scroll
          data-scroll-repeat
          as="span"
          variant="body"
          sx={{ fontFamily: 'medium' }}
        >
          {publishDate}
        </Text>
        <Text
          data-scroll
          data-scroll-repeat
          as="p"
          variant="body"
          sx={{ fontFamily: 'medium', marginBottom: 0 }}
        >
          {title}
        </Text>
      </Link>
    </Box>
  );
};

/**
 * Returns a grid of news
 */
export default function NewsList({ news, background, count, ...props }) {
  const items = [];

  if (!news) {
    return null;
  }

  for (let i = 0; i < (count || news.length); i++) {
    const post = news[i];
    const { publishedAt = '', image, title, slug, _id } = post || {};
    const postDate = publishedAt ? publishedAt.replace(/-/g, '.') : '';

    items.push(
      <NewsItem
        image={image}
        title={title}
        slug={slug}
        publishDate={postDate}
        background={background}
        key={_id || i}
      />,
    );
  }

  return (
    <Grid
      sx={{
        gridGap: 4,
        gridTemplateColumns: ['repeat(1,1fr)', 'repeat(12,1fr)'],
        width: '100%',
      }}
      {...props}
    >
      {items}
    </Grid>
  );
}
