/** @jsxRuntime classic */
/** @jsx jsx */
import { TinyColor } from '@ctrl/tinycolor';
import { blocksQuery } from 'api';
import { LogError } from 'business/constants';
import BlockContent from 'components/blocks/block-content';
import NewsBlock from 'components/blocks/news-block';
import ProjectListItem from 'components/pages/work/project-list-item';
import PageLayout from 'components/ui-components/layout/PageLayout';
import LoadingSpinner from 'components/ui-components/loading-spinner';
import ViewportHeightContainer from 'components/ui-components/viewport-height-container';
import SEO from 'components/utility-wrappers/seo';
import { Fragment, useEffect, useState } from 'react';
import { color } from 'styles';
import useSWR from 'swr';
import { Box, Flex, jsx, useThemeUI } from 'theme-ui';
import { changeBgColor, createBlocks } from 'utils/helpers';
import '../../../nav.scss';

const request = {
  params: {},
  query: `{
    'newspage':*[_type == 'newspage' && _id == 'newspage']{
      backgroundcolor,
      headline,
      seo,
      slug,
    }[0],
    'newsposts':*[_type == 'post' && !(_id in path("drafts.**"))] | order(publishedAt desc) {
      title,
      _id,
      slug,
      publishedAt,
      'image': main_image
    }[0...3],
    'workpage':*[_type == 'workpage' && _id == 'workpage']{
      slug,
    }[0],
    'homepage': *[_type == 'startpage' && _id == 'start']{
      seo,
      backgroundcolor,
      "bgVideo": bgVideo.asset->url,
      blocks,
      featuredprojects[]->{
        ...,
        "categories": categories[]->title,
      },
      ${blocksQuery}
      ...
    }[0],
  }`,
};

export default function Home(props) {
  const { data, error } = useSWR([request.query, request.params]);
  const { theme } = useThemeUI();

  useEffect(() => {
    changeBgColor(data?.homepage?.backgroundcolor || props.color);
  }, [props.color, data]);

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  const {
    workpage = {},
    newspage = {},
    newsposts = {},
    homepage = {},
  } = data || {};

  const contactGradientTransparent = new TinyColor(
    theme.colors.pink || 'rgba(0,0,0,0)',
  );
  contactGradientTransparent.toPercentageRgbString();
  contactGradientTransparent.setAlpha(0);

  return (
    <Fragment>
      <SEO {...(homepage.seo || {})} />
      <PageLayout
        data-page="home"
        innerContainerProps={{
          sx: {
            paddingTop: 0,
            paddingLeft: '0',
            paddingRight: '0',
            nav: {
              a: {
                color: 'white !important',
              },
            },
          },
        }}
      >
        <ViewportHeightContainer
          data-scroll-section
          sx={{
            minHeight: '100% !important',
            height: '100% !important',
          }}
        >
          {homepage.body ? (
            <Flex
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
              }}
            >
              <Flex
                sx={{
                  display: 'flex',
                  height: '100vh',
                  position: 'relative',
                  video: {
                    display: 'flex',
                    objectFit: 'cover',
                    minWidth: '100%',
                    minHeight: '100%',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: -1,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    top: 0,
                  },
                }}
              >
                {homepage.bgVideo && (
                  <video
                    width="1920"
                    height="1080"
                    playsInline={true}
                    muted={true}
                    autoPlay={true}
                    loop={true}
                    src={homepage.bgVideo}
                  ></video>
                )}
                <LoadingSpinner
                  sx={{
                    color: 'white',
                    width: [24, '7rem', 32, null, 40],
                    height: [24, '7rem', 32, null, 40],
                    position: 'absolute',
                    margin: '0',
                    left: '40px',
                    bottom: '40px',
                    top: 'unset',
                  }}
                />
              </Flex>
              <Flex
                sx={{
                  display: 'flex',
                  maxWidth: '1700px',
                  paddingLeft: ['20px', null, '40px'],
                  paddingRight: ['20px', null, '40px'],

                  'p[data-scroll="true"]': {
                    marginTop: '40px !important',
                  },
                }}
              >
                <BlockContent
                  data-scroll
                  data-scroll-repeat
                  sx={{
                    opacity: 0,
                    fontSize: [3, 4, 5, 6],
                  }}
                  blocks={homepage.body || []}
                />
              </Flex>
            </Flex>
          ) : null}
        </ViewportHeightContainer>

        {homepage.preamble ? (
          <Box
            data-scroll-section
            sx={{
              marginLeft: 'auto',
              marginY: 0,
              maxWidth: [null, `${(100 / 12) * 6}%`],
              marginRight: [0, `${100 / 12}vw`],
              padding: ['20px', null, null],
            }}
          >
            {/* <Box data-scroll data-scroll-repeat data-scroll-speed={1.5}> */}
            <Box>
              <BlockContent blocks={homepage.preamble || []} />
            </Box>
          </Box>
        ) : null}

        {homepage.featuredprojects &&
          homepage.featuredprojects.map((project, i) => {
            return (
              <ProjectListItem
                sx={{ marginTop: '0' }}
                {...project}
                key={project._id || i}
                to={`/${workpage.slug.current}/${project.slug.current}`}
              />
            );
          })}

        {newspage && newsposts && (
          <Box
            data-scroll-section
            sx={{
              background: [
                `linear-gradient(
                  0deg,
                  ${theme.colors.yellow} 0%,
                  ${theme.colors.pink} 5%,
                  ${theme.colors.pink} 95%,
                  ${contactGradientTransparent} 100%
                )`,
                `linear-gradient(
                  0deg,
                  ${theme.colors.yellow} 0%,
                  ${theme.colors.pink} 10%,
                  ${theme.colors.pink} 90%,
                  ${contactGradientTransparent} 100%
                )`,
              ],

              marginTop: '4',
              paddingY: ['10rem', null, '30rem'],
              paddingX: [3, 0],
              marginX: [-3, 0],
            }}
          >
            <NewsBlock
              isScrollSection={false}
              headline={newspage.headline}
              news={newsposts}
              background={theme.colors.pink}
              count={3}
            />
          </Box>
        )}

        {homepage.blocks && (
          <Box
            data-scroll-section
            sx={{
              backgroundColor: 'yellow',
              position: 'relative',
              paddingY: [3, 5],
              paddingX: [3, 0],
              marginX: [-3, 0],
            }}
          >
            {createBlocks(homepage.blocks, false, false)}
          </Box>
        )}
      </PageLayout>
    </Fragment>
  );
}
