/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const setElHeight = (sectionRef, vh) => {
  if (typeof vh !== 'number') {
    return;
  }

  const ref = sectionRef.current;

  if (ref && typeof window !== 'undefined') {
    const newHeight = window.innerHeight / (100 / vh);
    ref.style.height = `${newHeight}px`;
  }
};

/**
 * Component that has the same effect as height="100vh", but works on mobile too.
 */
export const ViewportHeightContainer = ({ as = 'div', vh = 100, ...props }) => {
  const sectionRef = useRef(null);
  const vhRef = useRef(vh);

  useEffect(() => {
    setElHeight(sectionRef, vhRef.current);
  }, []);

  const El = as;

  return <El ref={sectionRef} {...props} />;
};

export default ViewportHeightContainer;

ViewportHeightContainer.propTypes = {
  as: PropTypes.string,
  vh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
