/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { forwardRef } from 'react';
import gsap from 'gsap';

import DelayLink from 'components/ui-components/delay-link';

const onDelayStart = () => {
  const elementsToFade = document.querySelectorAll('[data-transition-fade]');
  elementsToFade &&
    gsap.to(elementsToFade, { y: 50, opacity: 0, duration: 0.7 });
};

export const TransitionLink = forwardRef((props, ref) => {
  const { sx, ...rest } = props;
  return (
    <DelayLink
      delay={800}
      onDelayStart={onDelayStart}
      rel="preload"
      ref={ref}
      {...rest}
    />
  );
});

export default TransitionLink;
