/** @jsxRuntime classic */
/** @jsx jsx */
import { LogError } from 'business/constants';
import BlockContent from 'components/blocks/block-content';
import { TransitionLink } from 'components/ui-components/link';
import LoadingSpinner from 'components/ui-components/loading-spinner';
import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';
import { Box, Grid, jsx } from 'theme-ui';

const request = {
  params: {},
  query: /* groq */ `*[_type == 'site_settings' && !(_id in path("drafts.**"))]{
    footer{
      column_group[]{
        body[]{
          _type == "employee" => {
            "employee": @->{...}
          },
          _type == "office_reference" => {
            "office": @->{...}
          },
          ...
        },
        ...
      }
    }
    }[0]`,
};

const Footer = (props) => {
  const { data, error } = useSWR([request.query.toString(), request.params]);

  const { path } = useRouteMatch();
  const isLandingPage = path === '/';

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  const { footer } = data;

  return (
    <Box
      as="footer"
      sx={{
        paddingLeft: [4, `${100 / 12}vw`],
        paddingRight: [4, `${100 / 12}vw`],
        marginTop: [5, null, 6],
        marginBottom: [5, null, null],
        position: 'relative',
        ...(isLandingPage
          ? {
              backgroundColor: 'yellow',
              margin: [0, 0, 0, 0],
              paddingTop: [5, 6],
              paddingBottom: 5,
            }
          : {}),
      }}
      {...props}
    >
      <Grid
        data-transition-fade
        role="article"
        sx={{
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: [1, 2, 3],
        }}
      >
        <TransitionLink to="/" skew={false} aria-label="home">
          <LoadingSpinner
            sx={{
              height: 24,
              position: 'static',
              marginTop: 0,
              marginRight: 0,
              marginBottom: 3,
              marginLeft: 0,
              gridColumn: ['span 12', 'span 12', 'span 1'],
            }}
          />
        </TransitionLink>
        {footer?.column_group &&
          footer.column_group.map((column, i) => {
            return (
              <Box
                as="section"
                mr={[0, 2, 2]}
                sx={{
                  gridColumn: ['span 12', 'span 4', 'span 3'],
                }}
                key={column._key || i}
              >
                <BlockContent blocks={column.body || []} />
              </Box>
            );
          })}
      </Grid>
    </Box>
  );
};

export default Footer;
