/** @jsxRuntime classic */
/** @jsx jsx */
import ContentBlock from 'components/blocks/content-block';
import NewsList from 'components/blocks/news-block/news-list';
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import { BlockContainer } from 'utils/helpers/createBlocks';

export const NewsBlock = ({
  isScrollSection = true,
  headline,
  news,
  background,
  count,
}) => {
  return (
    <Fragment>
      {headline && (
        <BlockContainer isScrollSection={isScrollSection}>
          <ContentBlock headline={headline} />
        </BlockContainer>
      )}
      {news && (
        <NewsList
          isScrollSection={isScrollSection}
          news={news}
          background={background}
          count={count}
          sx={{
            paddingX: [3, `${100 / 12}vw`],
            paddingY: [3, 4, 5],
          }}
          {...(isScrollSection ? { 'data-scroll-section': true } : {})}
        />
      )}
    </Fragment>
  );
};

export default NewsBlock;
