/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Heading, Text } from 'theme-ui';
import { createPositionedGridItems } from 'utils/helpers';
import { GRID_ROWS, GRID_COLUMNS } from 'utils/constants';
import { GridRowOverlay } from 'utils/helpers/grid-overlay';
import Link from 'components/ui-components/transition-link';

const unit = 100 / GRID_COLUMNS; // one column width

const ProjectInfo = ({ to, categories, children }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          maxWidth: 'min-content',
        }}
      >
        <Box
          skew={false}
          sx={{
            display: ['block', 'inline-block'],
            position: 'relative',
            textAlign: 'center',
            maxWidth: ['none', 'min-content'],
            width: ['100%', 'auto'],
          }}
          data-scroll
          data-scroll-repeat
        >
          <Box sx={{ position: 'relative' }}>
            <Heading
              as="h3"
              variant="h1"
              sx={{
                fontSize: [7, 9, 11],
                letterSpacing: [-2, -3, -5],
                marginBottom: [2, 3],
                display: ['block', 'inline-block'],
              }}
              children={children}
            />
          </Box>
        </Box>
        <Text
          sx={{
            display: ['none', null, 'block'],
            marginLeft: [1, 2],
            textAlign: 'left',
            width: '100%',
            maxWidth: '60rem',
          }}
          as="p"
          data-scroll
          data-scroll-repeat
        >
          {categories?.map((item) => {
            return item + '. ';
          })}
        </Text>
      </Box>
    </Box>
  );
};

export default function ProjectListItem(props) {
  const { title, categories, list_grid, to, ...rest } = props;

  return (
    <Box
      role="article"
      data-scroll-section
      sx={{ position: 'relative', marginY: `${unit * 2}vw` }}
      {...rest}
    >
      <Link to={to}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: `${unit * GRID_ROWS}vw`,
          }}
        >
          {createPositionedGridItems({
            items: list_grid,
            columns: GRID_COLUMNS,
            rows: GRID_ROWS,
          })}
          <GridRowOverlay />
        </Box>
        <ProjectInfo categories={categories} to={to}>
          {title}
        </ProjectInfo>
      </Link>
    </Box>
  );
}
