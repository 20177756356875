import { useState, useEffect } from 'react';
import { breakpoints } from 'theme';

// A helper that returns the smallest breakpoint that the value passes, from mobile upwards.
// It's important that the theme file is set up correctly with breakpoints.
// if array of breakpoints is: ['600px', '960px', '1280px'] and window width is 800px, it will return the number 1.
const getBreakPoint = (windowWidth) => {
  if (windowWidth) {
    let activeIndex = undefined;

    for (let i = 0; i < breakpoints.length; i++) {
      const currentVal = breakpoints[i];

      if (windowWidth < parseInt(currentVal, 10)) {
        activeIndex = i;
        break;
      }
    }
    return activeIndex;
  } else {
    return undefined;
  }
};

// The hook itself
const useBreakpoint = () => {
  const isWindowClient = typeof window === 'object';

  const [breakPoint, setBreakPoint] = useState(
    isWindowClient
      ? getBreakPoint(window.innerWidth) //👈
      : undefined,
  );

  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setBreakPoint(getBreakPoint(window.innerWidth)); //👈
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener('resize', setSize);

      //unregister the listerner on destroy of the hook
      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setBreakPoint]);

  return breakPoint;
};

export default useBreakpoint;
